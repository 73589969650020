<template>
  <div class="meta">
    <div class="content">
      <div class="num">当前预约人数: {{ info.number }}</div>
      <div class="box">
        <div class="boxBg"></div>
        <div class="boxContent">
          <div class="h1">
            *活动时间内拥有【华夏元宇宙初级通行证】或【华夏元宇宙高级通行证】即为预约成功
          </div>
          <div class="h1" style="margin-top: 10px">如何获得通行证？</div>
          <div class="h2">
            全平台用户在活动期间首次参与签到，即可获得【华夏元宇宙初级通行证】
          </div>
          <div class="h2">
            新用户注册且完成实名认证，可获得【华夏元宇宙初级通行证】
          </div>
          <div class="h2">
            老用户邀请新用户注册且完成实名认证后，可获得【华夏元宇宙高级通行证】
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'meta',
  data() {
    return {
      info: {},
    };
  },
  mounted() {
    this.$api.joinPeople().then((e) => {
      if (e.code === 0) {
        this.info = e.data;
      } else {
        this.$toast(e.msg);
      }
    });
  },
};
</script>

<style scoped lang="less">
.meta {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(../../assets/images/meta.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  .content {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translateX(-50%);
    margin-top: 20px;
    width: 80%;
    height: 360px;
    display: flex;
    flex-direction: column;
    .num {
      color: #eee;
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  .box {
    flex: 1;
    // position: absolute;
    // left: 0;
    // bottom: 0;
    position: relative;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    line-height: 24px;
    .boxBg {
      border-radius: 8px;
      border: 1px solid #7183e9;
      opacity: 0.7;
      background: linear-gradient(-15deg, #1d20b9 0%, #813dc7 100%);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 9;
    }
    .boxContent {
      position: relative;
      z-index: 99;
    }
    .h1 {
      font-size: 15px;
      color: #eee;
      font-weight: 600;
    }
    .h2 {
      font-size: 14px;
      line-height: 24px;
      color: #eee;
      padding-left: 8px;
      position: relative;
      &::before {
        display: block;
        width: 4px;
        height: 4px;
        background: #eee;
        border-radius: 50%;
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
  }
}
</style>
